import axios from "axios";
import { API_BASE_URL, getToken } from "../utils/utility";

export default function AminController() {
	const token = getToken();
	//> isAdminUser
	this.isAdminUser = async function () {
		let result = false;
		if (!token) return result;
		try {
			const response = await axios.request({
				method: "get",
				url: `${API_BASE_URL}User/IsAdmin`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: null,
			});
			result = response.data;
		} catch (error) {
			console.warn(error.toString());
			result = false;
		}
		return result;
	};
	//> getSummary
	this.getSummary = async function (today) {
		let result = { isSuccess: true, message: "Success", value: null };
		try {
			const response = await axios.request({
				method: "get",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}Admin/GetSummary?isToday=${today}`,
				headers: {
					accept: "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: null,
			});
			result.value = response.data;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};
	//> getUsers
	this.getUsers = async function (pageNumber, pagingSize) {
		let result = { isSuccess: true, message: "Success", value: null, total: 0 };
		try {
			const response = await axios.request({
				method: "get",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}Admin/GetUsers?pageNumber=${pageNumber}&pagingSize=${pagingSize}`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: null,
			});
			result.value = response.data.value;
			result.total = response.data.total;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};
	//> ExportUsers
	this.exportUsers = async function () {
		let result = { isSuccess: true, message: "Success", value: null, total: 0 };
		try {
			const response = await axios.request({
				method: "get",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}Admin/ExportUsers`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: null,
			});
			result.value = response.data.value;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};
	//> getUserProfile
	this.getUserProfile = async function (userId) {
		let result = { isSuccess: true, message: "Success", value: null, total: 0 };
		try {
			const response = await axios.request({
				method: "get",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}Admin/GetUserProfile?userId=${userId}`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: null,
			});
			result.value = response.data;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};
	//> getLevels
	this.getLevels = async function () {
		let result = { isSuccess: true, message: "Success", value: null, total: 0 };
		try {
			const response = await axios.request({
				method: "get",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}Admin/GetLevels`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: null,
			});
			result.value = response.data.value;
			result.total = response.data.total;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};
	//> updateLevel
	this.updateLevel = async function (
		levelId,
		code,
		name,
		title,
		summary,
		notes,
		minDecile,
		maxDecile,
		offPercent,
		sortOrder
	) {
		let result = { isSuccess: true, message: "Success", value: null };
		const token = getToken();
		try {
			const response = await axios.request({
				method: "post",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}Admin/UpdateLevel`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: JSON.stringify({
					levelId: levelId,
					code: code,
					name: name,
					title: title,
					summary: summary,
					notes: notes,
					minDecile: minDecile,
					maxDecile: maxDecile,
					offPercent: offPercent,
					sortOrder: sortOrder,
				}),
			});
			result.value = response.data;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};
	//> addLevelImage
	this.addLevelImage = async function (levelId, file) {
		let result = { isSuccess: true, message: "Success", value: null };
		const token = getToken();
		try {
			let formData = new FormData();
			formData.append("file", file);
			formData.append("entityName", "Levels");
			formData.append("entityId", levelId);
			formData.append("keyName", "Image");

			const response = await axios.request({
				method: "post",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}File/Upload`,
				headers: {
					accept: "application/json",
					"Content-Type": "multipart/form-data",
					Authorization: `Bearer ${token}`,
				},
				data: formData,
			});
			result.value = response.data;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};

	//> getStores
	this.getStores = async function (storeType, pageNumber, pagingSize) {
		let result = { isSuccess: true, message: "Success", value: null, total: 0 };
		try {
			const response = await axios.request({
				method: "get",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}Admin/GetStores?storeType=${storeType}&pageNumber=${pageNumber}&pagingSize=${pagingSize}`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: null,
			});
			result.value = response.data.value;
			result.total = response.data.total;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};
	//> updateStore
	this.updateStore = async function (
		storeId,
		name,
		rowNumber,
		offPercent,
		summary,
		notes,
		dayOfWork,
		startTime,
		endTime,
		latitude,
		longitude,
		address,
		tel,
		otherTel,
		website,
		telegram,
		instagram,
		subCategoryName,
		parentCategoryName,
		stateName,
		cityName,
		area,
		storeType,
		userId,
		dailyCapacity
	) {
		let result = { isSuccess: true, message: "Success", value: null };
		const token = getToken();
		try {
			const response = await axios.request({
				method: "post",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}Admin/UpdateStore`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: JSON.stringify({
					storeId: storeId,
					name: name,
					rowNumber: rowNumber,
					offPercent: offPercent,
					summary: summary,
					notes: notes,
					dayOfWork: dayOfWork,
					startTime: startTime,
					endTime: endTime,
					latitude: latitude,
					longitude: longitude,
					address: address,
					tel: tel,
					otherTel: otherTel,
					website: website,
					telegram: telegram,
					instagram: instagram,
					subCategoryName: subCategoryName,
					parentCategoryName: parentCategoryName,
					stateName: stateName,
					cityName: cityName,
					area: area,
					storeType: storeType,
					userId: userId,
					dailyCapacity: dailyCapacity,
				}),
			});
			result.value = response.data;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};
	//> addStoreImage
	this.addStoreImage = async function (storeId, file) {
		let result = { isSuccess: true, message: "Success", value: null };
		const token = getToken();
		try {
			let formData = new FormData();
			formData.append("file", file);
			formData.append("entityName", "Crm_Stores");
			formData.append("entityId", storeId);
			formData.append("keyName", "Image");

			const response = await axios.request({
				method: "post",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}File/Upload`,
				headers: {
					accept: "application/json",
					"Content-Type": "multipart/form-data",
					Authorization: `Bearer ${token}`,
				},
				data: formData,
			});
			result.value = response.data;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};
}
